export enum FeatureFlags {
  HofyExplanationExperimentId = 'hofyExplanationExperimentId',
  EorShorterSignUpExperimentId = 'eorShorterSignUpExperimentId',
  EorAIValidationEnabledDocs = 'eorAIValidationEnabledDocs',
  EorW4ExemptionFeatureInfo = 'eorW4ExemptionFeatureInfo',
  OnboardingFrameworkV2 = 'onboardingFrameworkV2',
  ContractorOnboardingFrameworkV2 = 'profileChecklistContractorsFrameworkV2',
  PhoneNumberGlobalPayrollSignUpFlow = 'onboarding.phoneNumberGlobalPayrollSignUpFlow',
  IsOnboardingChecklistConfigurationEnabled = 'isOnboardingChecklistConfigurationEnabled',
  OffboardingWorkerResignation = 'offboardingWorkerResignation',
  TaxIdCountryList = 'deelCard.taxIdCountryList',
  decoupleContractTabs = 'decoupleContractTabs',
  DeelCardPoa = 'deel_card_poa',
  DisplayProofAddressBanner = 'deelCard.displayProofAddressBanner',
  HideReconfirmPoaDialog = 'deelCard.hideReconfirmPoaDialog',
  ShowReplacementCards = 'deelCard.showReplacementCards',
  DisplayPreOrderBanner = 'deelCard.displayPreOrderBanner',
  DisplayAddFundsFromAdvance = 'deelCard.displayFundFromAdvance',
  DisablePhysicalCardOrdering = 'deelCard.disablePhysicalCardOrdering',
  CardOverviewRefresh = 'deelCard.cardOverviewRefresh',
  LimitPhyCountriesOnlyToDeeler = 'deelCard.limitPhyCountriesOnlyToDeeler',
  DeelCardEor = 'deelCard.eor',
  DeelCardAlviere = 'deelCard.alviere',
  DeelCardNiumReplacementFlow = 'DeelCardNiumReplacementFlow',
  PayslipsPaydatesStandardisationIsActive = 'payslipsPaydatesStandardisation.isActive',
  PayslipsPaydatesStandardisationIsDeelersOnly = 'payslipsPaydatesStandardisation.isDeelersOnly',
  PaymentsAndPayslipsUnified = 'paymentsAndPayslipsUnified',
  PaymentsAndPayslipsUnifiedForDeelersOnly = 'paymentsAndPayslipsUnifiedForDeelersOnly',
  EarnedWageAccessMainFeature = 'earnedWagedAccessMainFeature',
  EarnedWageAccessIsAutoApprovalEnabled = 'earnedWageAccessIsAutoApprovalEnabled',
  EarnedWageAccessIsAutoIssuanceEnabled = 'earnedWageAccessIsAutoIssuanceEnabled',
  SingleBankAccountExperience = 'singleBankAccountExperience',
  EwaEnabledOrgs = 'ewa.enabledForOrgs',
  EwaOrgsWithG2N = 'ewa.orgsWithG2N',
  EwaEmployeeCountryWhiteList = 'ewa.employeeCountryWhiteList',
  EwaDowntimeService = 'ewa.downtime.service',
  EwaDowntimeMaintenance = 'ewa.downtime.maintenance',
  EwaDowntimeTransactions = 'ewa.downtime.transactions',
  HideOffCyclePayments = 'hideOffCyclePayments',
  SalaryDistributionsByAmount = 'salaryDistributionsByAmount',
  EarnedWageAccessQ4Feature = 'earnedWagedAccess.Q4',
  HidePayslipsWithZeroNetPayments = 'shouldHidePayslipsWithZeroNetPayments',
  ClientWithdrawal = 'payments.clientBalance.shouldEnabledWithdrawal',
  ExpenseCardClientWithdrawal = 'expenseCardShouldEnableClientWithdrawal',
  ExpenseCardFilter = 'expenseCardFilter',
  FinanceWidgetIsActive = 'financeWidgetIsActive',
  FinanceWidgetIsActiveForDeelersOnly = 'financeWidgetIsActiveForDeelersOnly',
  FinanceWidgetNewUIEnabled = 'widget.financeWidgetNewUI',
  ExpenseCardEnabled = 'expenseCard.enabled',
  ExpenseCardPhysicalCardsIssuance = 'expenseCardPhysicalCardsIssuance',
  PaymentHistoryIsSortedDescendingly = 'paymentHistoryIsSortedDescendingly',
  AdvanceSplitYourPayCheck = 'advance.splitYourPayCheck',
  AdvanceAccountUnsubscribedList = 'advance.accountUnsubscribedList',
  HideAdvanceEligibilityCriteria = 'advance.hideEligibilityCriteria',
  AdvanceEarlyExternalRepaymentEnabled = 'advance.earlyExternalRepayment.enabled',
  AdvanceEarlyExternalRepaymentDisabledCountries = 'advance.earlyExternalRepayment.disabledCountries',
  AdvanceNoLongerAvailableForUserList = 'advance.noLongerAvailableForUserList',
  AdvanceRevamp = 'advance.revamp',
  AdvanceRevampV2 = 'advance.revamp.v2',
  AllowLENewFlowForOrganization = 'allowLENewFlowForOrganization',
  IsHRISIdentityVerificationEnabled = 'isHRISIdentityVerificationEnabled',
  IsInterestSurveyEnabled = 'interestSurveyEnabled',
  MassOnboardingHrisDirectEmployee = 'massOnboardingHrisDirectEmployee',
  TrustedDevices = 'platformTrustedDevice',
  MFARecommendation = 'platformMfaRecommendation',
  NoMFAOnSignUp = 'platformNoMfaOnSignUp',
  ClientProductOfInterest = 'isClientProductOfInterestEnabled',
  NewAccountSettings = 'newAccountSettings',
  recaptchaDisabledOrganizations = 'recaptchaDisabledOrganizations',
  captchaEnabledRoutes = 'platform.captchaEnabledRoutes.routes',
  disableCaptcha = 'platform.disableCaptcha',
  CustomRoles = 'platformCustomRoles',
  CustomRolesOrgScope = 'platformCustomRolesOrganizationScope',
  CustomRolesEntityScope = 'platformCustomRolesEntityScope',
  AskForPhone = 'askForPhoneModal',
  GPEntityRolesEnabled = 'gpEntityRolesEnabled',
  ResellerEndClientRole = 'resellerEndClientRole',
  EmployeeAppForOrgLevel = 'globalPayroll.employeeAppForOrgLevel',
  i9Organizations = 'kyc.i9.directEmployees.allowedOrganizations',
  MobilityDeelSolutions = 'mobility.deelSolutions',
  ComplianceDocumentsTracker = 'complianceDocumentsTrackerEnabled',
  ContractDocumentsV2 = 'contractDocumentsV2Enabled',
  DocumentsOverwriteCustomDocumentPermission = 'overwriteManageCustomDocumentsPermission',
  CustomImmigrationDocument = 'mobility.customImmigrationDocument',
  ImmigrationGoogleDocs = 'mobility.googleDocs',
  ImmigrationGoogleDocsUserFlag = 'mobilityGoogleDocs',
  InvoiceImmutabilityBanner = 'contracts.invoiceImmutabilityBanner',
  eorVsEntityService = 'eorVsEntityService',
  TimeAttendanceOnCallEnabledFor = 'timeAttendanceOnCallEnabledFor',
  timeAttendanceOnCallLateSubmissionEnabledFor = 'timeAttendanceOnCallLateSubmissionEnabledFor',
  timeAttendanceIsDayTrackingEnabled = 'timeAttendance.isDayTracking.enabled',
  timeAttendanceIsDatagridEnabled = 'timeAttendance.isDatagrid.enabled',
  timeAttendanceTimeInconsistenciesEnabledFor = 'timeAttendanceTimeInconsistenciesEnabledFor',
  TimeAttendanceEorWorkScheduleCountriesEnabled = 'timeAttendance.TimeAttendanceEorWorkScheduleCountriesEnabled',
  TimeAttendanceRestDaysEnabled = 'timeAttendanceRestDaysEnabledFor',
  TimeAttendanceKITAvailabilityCountriesEnabled = 'timeAttendance.TimeAttendanceKITAvailabilityCountriesEnabled',
  TimeTrackingAllowTimeEntriesWithoutValidation = 'timeTrackingAllowTimeEntriesWithoutValidation',
  TimeTrackingWorkAssignments = 'timeTrackingWorkAssignments',
  TimeTrackingAutoFillEnabled = 'timeTrackingAutoFillEnabled',
  TimeTrackingSettingsEnabled = 'timeTrackingSettingsEnabled',
  TimeTrackingAutoFillConfirmationEnabled = 'TimeTrackingAutoFillConfirmationEnabled',
  AdjustmentsManager = 'adjustments.manager',
  DeelFillableTestOrgs = 'deelFillable.enableTestModeOrgs',
  isDeelCalendarEnabled = 'isDeelCalendarEnabled',
  isEquipmentNewChipEnabled = 'newEquipment.isNewChipEnabled',
  isKeptStatusEnabled = 'newEquipment.isKeptStatusEnabled',
  customDomainWindowTitleEnabledFor = 'customDomains.windowTitle.enabledFor',
  scopeOfWorkV2 = 'framework.ic.scopeOfWorkV2',
  gpMassAmendments = 'framework.gp.massAmendments',
  hrisMassAmendments = 'framework.hris.massAmendments',
  dataGridCustomFieldsEnabledForAll = 'dataGridCustomFieldsEnabledForAll',
  dataGridCustomFieldsEnabledFor = 'dataGridCustomFieldsEnabledFor',
  dataGridOrgStructureEnabledFor = 'dataGridOrgStructureEnabledFor',
  dataGridWorkerRelationsEnabledFor = 'dataGridWorkerRelationsEnabledFor',
  hasJobPositionHistory = 'hasJobPositionHistory',
  visaSupportedCountries = 'immigration.visaSupportedCountries',
  contractsEnabledWorkLocation = 'contractsEnabledWorkLocation',
  peopleListDataGridDisabledFor = 'peopleListDataGridDisabledFor',
  HofyTerminationFlow = 'leasedEquipment.hofyTerminationFlow.betatesters',
  ProvidedEquipmentTerminationFlow = 'providedEquipment.terminationFlow.betatesters',
  AlvierePlaidVerificationEnabledFor = 'alvierePlaidVerificationEnabledFor',
  ShowLinkToReportInMassPay = 'showLinkToReportInMassPay',
  ShowInvoicePdfPreviewInMassPay = 'showInvoicePdfPreviewInMassPay',
  customFieldsImprovementsEnabledFor = 'customFieldsImprovementsEnabledFor',
  contractsEnableAutofill = 'contractsEnableAutofill',
  TrackerProcesses = 'trackerProcesses',
  PremiumPricing = 'contracts.premiumPricing',
  HeartRelocationEnabled = 'heartRelocationEnabled',
  MigrationInfoPopupEnabled = 'migrationInfoPopupEnabled',
  SSOLoginFeature = 'ssoLoginFeature',

  // Entity Setup flags
  isEntitiesManagementEnabled = 'isEntitiesManagementEnabled',
  isCorporateAgentsEnabled = 'isCorporateAgentsEnabled',
  isBusinessActivityFieldEnabled = 'isBusinessActivityFieldEnabled',
  isShareManagementEnabled = 'isShareManagementEnabled',
  isEntityAddressesEnabled = 'isEntityAddressesEnabled',
  isUltimateBeneficialOwnerEnabled = 'isUltimateBeneficialOwnerEnabled',
  isGeneralMeetingEnabled = 'isGeneralMeetingEnabled',
  isEntityCorporateChartsEnabled = 'isEntityCorporateChartsEnabled',

  // Benefits Feature Flags
  CLIENT_OPEN_ENROLLMENT = 'benefitsClientOpenEnrollment',
  CLIENT_PROVIDERS_MANAGEMENT = 'benefitsClientProvidersManagement',
  CLIENT_QLE = 'benefitsClientQLE',
  CLIENT_BENEFITS = 'benefitsClientBenefits',
  BENEFITS_CLIENT_APP = 'benefitsClientApp',
  BENEFITS_CLIENT_APP_OVERVIEW_TAB = 'benefitsClientAppOverviewTab',
  BENEFITS_CLIENT_APP_CARRIER_AND_PLANS_TAB = 'benefitsClientAppCarrierAndPlansTab',
  BENEFITS_CLIENT_APP_COMPANY_TAB = 'benefitsClientAppCompanyTab',
  BENEFITS_CLIENT_APP_EMPLOYEES_TAB = 'benefitsClientAppEmployeesTab',
  BENEFITS_CLIENT_APP_CHANGE_LOGS_TAB = 'benefitsClientAppChangeLogsTab',
  BENEFITS_CLIENT_APP_FULFILLMENT_TAB = 'benefitsClientAppFulfillmentTab',
  BENEFITS_CLIENT_APP_REPORTS_TAB = 'benefitsClientAppReportsTab',
  BENEFITS_CLIENT_APP_REPORTS_CENSUS = 'benefitsClientAppReportsCensus',
  BENEFITS_CLIENT_APP_REPORTS_COSTS = 'benefitsClientAppReportsCosts',
  BENEFITS_CLIENT_APP_REPORTS_CHANGES = 'benefitsClientAppReportsChanges',
  BENEFITS_CLIENT_APP_REPORTS_WAIVERS = 'benefitsClientAppReportsWaivers',
  BENEFITS_CLIENT_APP_REPORTS_OPEN_ENROLLMENTS = 'benefitsClientAppReportsOpenEnrollments',
  BENEFITS_CLIENT_APP_REPORTS_OPEN_ENROLLMENTS_CHANGES = 'benefitsClientAppReportsOpenEnrollmentsChanges',
  BENEFITS_CLIENT_APP_QLE_TAB = 'benefitsClientAppQLETab',
  BENEFITS_CLIENT_APP_EMPLOYEE_DETAILS = 'benefitsClientAppEmployeeDetails',
  BENEFITS_CLIENT_APP_EMPLOYEE_DETAILS_OVERVIEW_TAB = 'benefitsClientAppEmployeeDetailsOverviewTab',
  BENEFITS_CLIENT_APP_EMPLOYEE_DETAILS_COVERAGES_TAB = 'benefitsClientAppEmployeeDetailsCoveragesTab',
  BENEFITS_CLIENT_APP_EMPLOYEE_DETAILS_ACTIVITY_LOG_TAB = 'benefitsClientAppEmployeeDetailsActivityLogTab',
  BENEFITS_CLIENT_APP_EMPLOYEE_DETAILS_ELIGIBILITY_TAB = 'benefitsClientAppEmployeeDetailsEligibilityTab',
  BENEFITS_CLIENT_PLAN_CONFIGURATOR = 'benefitsClientPlanConfigurator',
  DISPLAY_BENEFITS = 'benefitsDisplayBenefits',
  DISPLAY_EMPLOYEE_DEPENDENTS = 'benefitsDisplayEmployeeDependents',
  EMPLOYEE_BENEFITS = 'benefitsEmployeeBenefits',
  EMPLOYEE_QLE = 'benefitsEmployeeQLE',
  USE_QLE_EMPLOYEE_FEATURE = 'useQLEBenefitsFeature',
  EOR_EMPLOYEE_QLE = 'benefitsEOREmployeeQLE',
  SHOW_BENEFIT_DETAILS_MODAL_DISCLAIMER = 'benefitsShowBenefitDetailsModalDisclaimer',
  SHOW_BENEFIT_DETAILS_MODAL_DISCLAIMER_FIRST_MESSAGE = 'benefitsShowBenefitDetailsModalDisclaimerFirstMessage',
  SHOW_BENEFIT_DETAILS_MODAL_DISCLAIMER_SECOND_MESSAGE = 'benefitsShowBenefitDetailsModalDisclaimerSecondMessage',
  SHOW_PROVIDER_PLAN_COMPARISON = 'benefitsShowProviderPlanComparison',
  HIDE_BENEFITS_COST = 'hideBenefitsCostData',
  HIDE_LUNCH_MONTHLY_ALLOWANCE = 'hideLunchMonthlyAllowance',
  HIDE_MANAGEMENT_TYPE = 'hideManagementType',
  // -----
  CONTRACT_EXTENSION = 'contractExtension',
  TEMPLATES_DISCOVERABILITY = 'contractTemplatesDiscoverability',
  OFF_PLATFORM_CONTRACT_TYPE = 'offPlatformContract',
  CONTRACTS_AI_SOW = 'contractsAiScopeOfWork',
  CONTRACTS_AI_PREDICTIONS = 'contractsAiPredictions',
  W9_TAX_FORM_VALIDATION_POPUP = 'contractW9TaxFormValidationPopup',
  kycFlowEnabledFor = 'kycFlowEnabledFor',
  localizedPayrollEnabledFor = 'localizedPayrollEnabledFor',
  hideLocationSectionsFor = 'hideLocationSectionsFor',
  hiringWorkerAssessment = 'shieldHiringWorkerAssessment',
  shieldBannerUpgradeExperimentEnabled = 'shieldBannerUpgradeExperimentEnabled',
  OptimizelyExperimentDisabled = 'optimizely.disabled',
  WorkerOrgSwitcherEnabledFor = 'workerOrgSwitcherEnabledFor',
  HrisNewProfileEnabledFor = 'hrisNewProfileEnabledFor',
  enableChangelogV2 = 'enableChangelogV2',
  enableDataChangePolicies = 'enableDataChangePolicies',
  enableMultipleDataChange = 'enableMultipleDataChange',
  enablePersonalInformationModificationReason = 'enablePersonalInformationModificationReason',
  RejectedWithdrawalsBannerEnabled = 'withdrawals.showRejectedWithdrawalsBanner',
  UpgradeToEorExperimentId = 'eor.upgradeToEorExperimentId',
  CorContinuousComplianceExperiment = 'shield.continuousComplianceExperiment',
  OrgChartGroupByCustomField = 'orgChartSortBarEnabledFor',
  OrgChartGroupByV2 = 'isOrgChartGroupByV2',
  OrgStructuresSettings = 'orgStructuresSettingsEnabledFor',
  WorkforcePlanningProductEnabledFor = 'workforcePlanningProductEnabledFor',
  WorkforcePlanningAccessScreen = 'workforcePlanningAccessScreen',
  HasDropdownDepartmentGp = 'hasDropdownDepartmentGp',

  ShowCardOnsSettingSection = 'showCardOnsSettingSection',
  pendingRequests = 'pendingRequests',
  TimesheetsApprovalFlow = 'approvalFlow.timesheets',
  ChangeTimesheetsApprovalPolicy = 'approvalFlow.changeTimesheetsApprovalPolicy',
  massOnboardingNewErrorHandlingEnabledFor = 'massOnboardingNewErrorHandlingEnabledFor',
  newUIWorkerOrgSwitcherEnabledFor = 'newUIWorkerOrgSwitcherEnabledFor',
  newPersonalAccountSettings = 'newPersonalAccountSettings',
  directManagerHomeWidget = 'directManagerHomeWidget',
  coworkerPermissions = 'coworkerPermissions',
  hideSeniorityLevelProfile = 'hideSeniorityLevelProfile',
  fuzzyMatchingEnabledFor = 'fuzzyMatchingEnabledFor',
  workerWithNoContract = 'workerWithNoContractEnabledFor',
  directEmployeeContractEditAllFields = 'directEmployeeContractEditAllFieldsEnabledFor',
  timeoffForPwacEnabled = 'timeoffForPwacEnabledFor',
  demographicFieldsPerCountry = 'demographicFieldsPerCountry',
  supportEscalationWarning = 'supportEscalationWarning',
  myProfileRedesign = 'myProfileRedesign',
  isFieldViewPermissionEnabled = 'isFieldViewPermissionEnabled',
  isFieldEditPermissionEnabled = 'isFieldEditPermissionEnabled',
  isEndOfContractBoxEnabled = 'isEndOfContractBoxEnabled',
  isBulkEditPWACEnabled = 'isBulkEditPWACEnabled',
  isNewCustomFieldsPrivacyEnabled = 'isNewCustomFieldsPrivacyEnabled',
  isHrisSignupFieldsOptional = 'isHrisSignupFieldsOptional',
  // Payouts Feature Flags
  AstropayWithdrawals = 'withdrawals.astropay',
  DolarAppWithdrawals = 'withdrawals.dolarapp',
  NewWithdrawalMethodsFlow = 'newWithdrawalMethodsFlow',
  SupportDolarApp = 'dolarapp',
  RefundRecallWithdrawal = 'refundRecallWithdrawal',
  DeelWalletUpdateKycAction = 'deelWalletUpdateKycAction',
  PixWithdrawals = 'withdrawals.pix',
  SupportPix = 'pix',
  BVNKWithdrawals = 'withdrawals.bvnk',
  DeelCardWithdrawals = 'withdrawals.deelCard',
  isBVNKEnabledForUser = 'bvnk',
  deelCardMobilePayIntegration = 'deelCardMobilePayIntegration',
  profilesSupportedByPaysend = 'profilesSupportedByPaysend',
  orderFormsBannerShown = 'contractsOrderFormsBannerShown',
  learningEngage = 'engage.learning.isEnabled',
  learningSCORMAnalyticsEnabled = 'engageLearningScormAnalyticsEnabled',
  engageLearningProgramsEnabled = 'engageLearningProgramsEnabled',
  Performance = 'engagePerformance',
  hiringIsEnabled = 'hiring.isEnabled',
  hiringIsInterviewSchedulingEnabled = 'hiring.isInterviewSchedulingEnabled',
  passwordRotation = 'passwordRotation',
  mobilityDisableHourlyEor = 'mobilityDisableHourlyEor',
  mobilityCaseIntroductoryModal = 'mobilityCaseIntroductoryModal',
  agnosticImmigrationFlow = 'mobility.agnosticImmigrationFlow',
  eorAutoSave = 'eorAutoSave',
  useEdmForAdditionalData = 'useEdmForAdditionalData',
  adjustInvoiceRowTitle = 'adjustInvoiceRowTitle',
  mobilityApplicantImmigrationHome = 'mobilityApplicantImmigrationHome',
  mobilityReportsCTAEnabled = 'mobilityReportsCTAEnabled',
  mobilityCostsAndWorkforceWidgets = 'mobilityCostsAndWorkforceWidgets',
  mobilityIndividualSignup = 'mobility.individualSignup',
  visaEligibilityAiTool = 'mobilityVisaEligibilityAiTool',
  viewLinkedCases = 'mobilityViewLinkedCases',
  viewLinkedProcessQuotes = 'mobilityLinkedProcessQuotes',
  // Integrations Feature Flags
  integrationsQboGrowthFunnelUsersList = 'integrationsQboGrowthFunnelUsersList',
  integrationsDocumentsSyncEnableAllControls = 'integrationsDocumentsSyncEnableAllControls',
  integrationsWorkerDataSyncIntegrationsList = 'integrations.enableWorkerDataSyncIntegration',
  integrationsUseAccountingLineItemTypesNewEndpoint = 'integrations.useAccountingLineItemTypesNewEndpoint',
  integrationsGpPeopleSyncFileValidations = 'integrationsGpPeopleSyncFileValidations',
  // Extra2 Feature Flags
  CoworkingService = 'coworkingService',
  // Time Off Flags
  EnableTopOffPayment = 'timeOff.enableTopOffPayment',
  ParentalLeaveTimeOffTypes = 'timeOff.parentalLeaveTimeOffTypes',
  TimeOffPayoutsConfigEnabled = 'timeOff.payoutsConfigEnabled',
  BalanceExpiryEnabled = 'timeOff.enableBalanceExpiry',
  SegmentedAllowancePolicyTypes = 'timeOff.segmentedAllowancePolicyTypes',
  EnablePublicHolidaysSettings = 'timeOff.enablePublicHolidaysSettings',
  timeOffBalanceAdjustmentEnabledFor = 'timeOffBalanceAdjustmentEnabledFor',
  timeOffActivationImprovementsV2Enabled = 'timeOffActivationImprovementsV2Enabled',
  timeOffActivationImprovementsV3Enabled = 'timeOffActivationImprovementsV3Enabled',
  EnableFlaggedPoliciesFor = 'timeOff.enableFlaggedPoliciesFor',
  AllowCustomEORPoliciesFor = 'timeOff.allowCustomEORPoliciesFor',
  timeOffSectionEnabledFor = 'timeOff.timeOffSectionEnabledFor',
  TimeOffDashboardEnabled = 'timeOffDashboardEnabled',
  EnableWhoIsAwayDigest = 'timeOff.enableWhoIsAwayDigest',
  googleCalenderSyncEnabledFor = 'timeOff.googleCalenderSyncEnabledFor',
  // payment/invoices - AlertBillingSection control
  standaloneFeeInvoices = 'standaloneFeeInvoices',
  ShieldMassAssessment = 'shieldMassAssessment',
  ShieldMassEdit = 'shieldMassEdit',
  EORViewLatestG2N = 'eor.viewLatestG2NEnabled',
  // PMORG FF
  orgChartEnabledFor = 'orgChartEnabledFor',
  orgChartUpselling = 'orgChartUpselling',
  orgChartHideSeniority = 'orgChartHideSeniority',
  orgStructureOnContractCreation = 'isOrgStructureOnContractCreation',
  dynamicGroupsEntryPoint = 'dynamicGroupsEntryPoint',
  dynamicGroupsDeelAiEnabled = 'dynamicGroupsDeelAiEnabled',
  hasRoleWithoutPositionSection = 'hasRoleWithoutPositionSection',
  orgChartEnabledOnMobile = 'orgChartEnabledOnMobile',
  orgChartStructuresDragAndDrop = 'orgChartStructuresDragAndDrop',
  orgChartOrgStructuresWorkerRelationOptionals = 'orgChartOrgStructuresWorkerRelationOptionals',
  orgChartOrgStructuresSearchFilter = 'orgChartOrgStructuresSearchFilter',
  orgChartEmployeeOrgChartTotalAndDirectReports = 'orgChartEmployeeOrgChartTotalAndDirectReports',
  orgChartClusterGroupBySwitch = 'orgChartClusterGroupBySwitch',
  orgChartGroupByCustomWorkerRelations = 'orgChartGroupByCustomWorkerRelations',
  jobTitleDisableAdd = 'jobTitleDisableAdd',
  jobTitleIgnoreDefaultList = 'jobTitleIgnoreDefaultList',
  wfpAtsConnection = 'wfpAtsConnection',
  // Login as deel admin Feature Flag
  loginAsDeelAdminPermission = 'loginAsDeelAdminPermission',
  contractShieldDelegateSignature = 'contractShieldDelegateSignature',
  NewAuditLogs = 'newAuditLogs',
  EmailLogs = 'emailLogs',
  contractEORDelegateSignature = 'contractEORDelegateSignature',
  TAI_Projections = 'taiProjections',
  TAI_ProjectionsEnabledForCountries = 'tai.projectionsEnabledForCountries',
  isBottomNavigationEnabled = 'mobile.isBottomNavigationEnabled',
  isNativeHomeCustomizationEnabled = 'mobile.isNativeHomeCustomizationEnabled',
  legacyOnboarding = 'useLegacyOnboarding',
  // Salary Insights Feature Flags
  SalaryInsightsDashboardSensitiveAccessClient = 'showDashboardToSensitiveAccessClient',
  SalaryInsightsTopHiredRoles = 'showTopHiredRoles',
  SalaryInsightsOverTime = 'showSalaryOverTime',
  hidePayslipsOverview = 'hidePayslipsOverview',
  unifiedCreation = 'framework.unifiedCreation',
  multipleSignaturesInIcMassImport = 'multipleSignaturesInIcMassImportEnabled',
  gpAmendmentEndDateEnabled = 'gpAmendmentEndDateEnabled',
  gpVariableCompensationEndDateEnabled = 'gpVariableCompensationEndDateEnabled',
  employmentAgreementsInDocumentsTabFF = 'employmentAgreementsInDocumentsTabFF',
  // Localization
  LocalizationTest = 'localizationTest',
  hidePRMPortal = 'hidePRMPortal',
  showDataUpdates = 'showDataUpdates',
  hideEmployeeListRevamp = 'hideEmployeeListRevamp',
  shieldHighlightCoverage = 'shieldHighlightCoverage',
  isDemoWorkerImpersonateEnabled = 'isDemoWorkerImpersonateEnabled',
  notificationSettingsForWorkers = 'notificationSettingsForWorkers',
  // EOR
  EORContractCreationReasonsDropdownRandomize = 'eorContractCreationReasonsDropdownRandomize',
  EORTerminationReasonsDropdownRandomize = 'eorTerminationReasonsDropdownRandomize',
  showAdjustmentsRevamp = 'showAdjustmentsRevamp',
  serviceProposalLetter = 'shield.serviceProposalLetter',
  eorIsExpediteExperienceEnabled = 'eor.isExpediteExperimentEnabled',
  eorIsAICheckScopeRequestChangeEnabled = 'eor.isAICheckScopeRequestChangeEnabled',
  eorAiServiceForScopeCheck = 'eor.aiServiceForScopeCheckExperiment',
  selfSponsoredVisaBooking = 'selfSponsoredVisaBooking',
  // COR
  shieldEssentials = 'shieldEssentials',
  corToggleDefaultState = 'corToggleDefaultState',
  eorBillingFeesEnabled = 'eor.billingFeesEnabled',
  corAdditionalQuestions = 'corAdditionalQuestions',
  corUpgradeFocusFlow = 'corUpgradeFocusFlow',
  // EOR Cost Calculator
  isCostCalculatorEnabled = 'eor.isCostCalculatorEnabled',
  PostQAReports = 'postQAReports',
  // General Cost Calculator
  workerCostCalculatorEnabled = 'workerCostCalculatorEnabled',
  workerCostCalculatorDisabledWorkerTypes = 'workerCostCalculator.disabledWorkerTypes',
  // Global Hiring Insights
  globalHiringInsightsEnabled = 'globalHiringInsights.enabled',
  // Transition
  contractPrefill = 'contractPrefill',
  contractTransitionICToPEO = 'contractTransitionICToPEO',
  contractTransitionHRISToIC = 'contractTransitionHRISToIC',
  contractTransitionHRISToGP = 'contractTransitionHRISToGP',
  contractTransitionHRISToEOR = 'contractTransitionHRISToEOR',
  contractTransitionHRISToPEO = 'contractTransitionHRISToPEO',
  contractTransitionEORToPEO = 'contractTransitionEORToPEO',
  contractTransitionGPToGP = 'contractTransitionGPToGP',
  // Deel IT
  deelITAssetActionsEnabled = 'deelIT.assetActionsEnabled',
  deelITByodEnabled = 'deelIT.byodEnabled',
  deelITAssetBulkUploadEnabled = 'deelIT.assetBulkUploadEnabled',
  deelITShowDashboard = 'deelIT.showDashboard',
  deelITAppResaleEnabledApps = 'deelIT.appResale.enabledApps',
  deelITTechSupportEnabled = 'deelIT.techSupportEnabled',
  deelITAgentV1Enabled = 'deelIT.agentV1',
  // Deel API
  DeelApiHideSensitiveData = 'deelApiHideSensitiveData',
  // White Label
  isWhiteLabelSetupWizardEnabled = 'whiteLabel.isSetupWizardEnabled',
  isWhiteLabelFeaturesTabEnabled = 'whiteLabel.isFeaturesTabEnabled',
  isWhiteLabelNeutralizationEnabled = 'whiteLabel.isNeutralizationEnabled',
  isWhiteLabelHomepageRedirectBannerEnabled = 'whiteLabel.isHomepageRedirectBannerEnabled',
  isWhiteLabelConsentFlowEnabled = 'whiteLabel.isConsentFlowEnabled',
  isWhiteLabelCreateUserSupportedLinksHidden = 'whiteLabel.isCreateUserSupportedLinksHidden',
  isLoginCustomizedBasedonCustomDomain = 'whiteLabel.isLoginCustomizedBasedonCustomDomain',
  isPartnerPortalFrontendFeatureEnabled = 'partnerPortal.isEnabled',
  isPartnerPortalReferralsEnabled = 'partnerPortal.isReferralsEnabled',
  isPartnerPortalReferralsRevenueEnabled = 'partnerPortal.isReferralsRevenueShareEnabled',
  isPartnerPortalBackendFeatureEnabled = 'isPartnerPortalEnabled',
  // misclassification
  misclassificationHomePageBanner = 'misclassificationHomePageBanner',
  misclassificationHomePageWidget = 'misclassificationHomePageWidget',
  misclassificationPeoplePageBanner = 'misclassificationPeoplePageBanner',
  misclassificationIndividualPageAlert = 'misclassificationIndividualPageAlert',
  // Contracts
  NewMassUpdateAll = 'contracts.newMassUpdateFlow.enabledFor.enabledForEveryone',
  NewMassUpdateFor = 'contracts.newMassUpdateFlow.enabledFor.enabledForOrganizationIds',
  NewMassUpdateDisabledFor = 'contracts.newMassUpdateFlow.enabledFor.disabledForOrganizationIds',
  // Analytics and reporting
  isNewReportCreationFlowEnabled = 'isNewReportCreationFlowEnabled',
  DqlReportCreation = 'DqlReportCreation',
  isImpersonateSharerPermissionEnabled = 'isImpersonateSharerPermissionEnabled',
  isReportViewLiteEnabled = 'isReportViewLiteEnabled',
  isMonthRangeFilterEnabled = 'isMonthRangeFilterEnabled',
  // Workflows
  ShouldShowTriggerEditButton = 'workflows.shouldShowTriggerEditButton',
  HiddenPeopleFieldTriggers = 'workflows.hiddenPeopleFieldTriggers',
  HiddenTriggerType = 'workflows.hiddenTriggerType',
  HiddenPeopleFieldTypes = 'workflows.hiddenPeopleFieldTypes',
  HiddenRichTextEditorFeatures = 'workflows.hideRichTextEditorFeatures',
  TriggerVisibilityOverride = 'workflows.triggerVisibilityOverride',
  TinyMCEenabledFor = 'workflows.tinyMCEenabledFor',
  // Mobile
  isAppBasedMFAEnabled = 'mobileIsAppBasedMFAEnabled',
  isNativeSecuritySettingsEnabled = 'mobileIsNativeSecuritySettingsEnabled',
  isLoginWithQREnabled = 'mobile.isLoginWithQREnabled',
  showDownloadAppBanner = 'mobile.showDownloadAppBanner',
  //Knowledge hub
  KnowledgeHub = 'knowledgeHub',
  KnowledgeHub2 = 'knowledgeHub2',
  // Embedded Payroll
  signDocuments = 'embeddedPayrollSignDocuments',
  automatedBillingSetup = 'payInsAutomatedBillingSetup',
  // Contract Creation Autosave
  ContractCreationAutoSaveForIC = 'contractCreationAutoSaveForIC',
  ContractCreationAutoSaveForGP = 'contractCreationAutoSaveForGP',
  contractCreationAutoSaveForPEO = 'contractCreationAutoSaveForPEO',
  ContractCreationAutoSaveForHRIS = 'contractCreationAutoSaveForHRIS',
  ContractCreationAutoSaveForEOR = 'contractCreationAutoSaveForEOR',
  isVendorManagementAllocationsShown = 'isVendorManagementAllocationsShown',
  SkipBankDetailsStepExperimentId = 'eor.skipBankDetailsStepExperimentId',
  // PEO
  peoEnableElectionFlow = 'peoEnableElectionFlow',
  PEOEnableAutomaticPayroll = 'PEOEnableAutomaticPayroll',
  PEOEnableGarnishments = 'PEOEnableGarnishments',
  PEOEnableEditAgreementDetailsFlow = 'PEOEnableEditAgreementDetailsFlow',
  PEOEnableScopesOfWork = 'PEOEnableScopesOfWork',
  PEOBulkEdit = 'PEOBulkEdit',
  peoDocsCollectionEnabled = 'peoDocsCollectionEnabled',
  peoDocsCollectionStateForm = 'peoDocsCollectionStateForm',
  PEO_BENEFITS = 'benefitsPeoBenefits',
  peoDisableArbitrationAgreement = 'peoDisableArbitrationAgreement',
  PeoEmployeeMultipleBankAccounts = 'peoEmployeeMultipleBankAccounts',
  PeoUsBannerEnabled = 'peoUsBannerEnabled',
  PeoHideGlobalPayrollFor = 'peoHideGlobalPayrollFor',
  PeoCsaContractDetailsEnabled = 'peoCsaContractDetailsEnabled',
  PeoEmployeeMineralTask = 'peoEmployeeMineralTask',
  PeoClientMineralTask = 'peoClientMineralTask',
  PeoMineralTaskDisabledFor = 'peoMineralTaskDisabledFor',
  isPeoLeasedEquipmentEnabled = 'isPeoLeasedEquipmentEnabled',
  PEOEnablePayrollCheckJournalReport = 'peoEnablePayrollCheckJournalReport',
  PEOEnablePayrollBillingSummaryReport = 'peoEnablePayrollBillingSummaryReport',
  PEOEnableCompleteAgreements = 'peoEnableCompleteAgreements',
  PeoEnableMassOnboarding = 'peoEnableMassOnboarding',
  PEOJobTitleSectionV2 = 'PEOJobTitleSectionV2',
  PEOJobTitleSectionV3 = 'PEOJobTitleSectionV3',
  PEOScopeOfWorkV3 = 'PEOScopeOfWorkV3',
  PEOCensusUploadImprovements = 'PEOCensusUploadImprovements',
  PEOStartDateLogicChanges = 'PEOStartDateLogicChanges',
  isNewPEOMinimumWageEnabled = 'isNewPEOMinimumWageEnabled',
  PEOEnableNoWageGroupMembers = 'PEOEnableNoWageGroupMembers',
  // Invoicing
  invoicePdfDataSettingsEnabled = 'invoicePdfDataSettingsEnabled',
  // Native finance
  NativeFinanceDeelCard = 'mobile.financeDeelCard',
  NativeFinanceDeelCardIncremental = 'mobileFinanceDeelCard',
  NativeFinanceDeelAdvanced = 'mobile.financeDeelAdvance',
  NativeFinanceDeelAdvancedIncremental = 'mobileFinanceDeelAdvance',
  NativeFinanceWithdraw = 'mobile.financeWithdraw',
  NativeFinanceWithdrawIncremental = 'mobileFinanceWithdraw',
  NativeFinanceInvoices = 'mobile.financeInvoices',
  NativeFinanceInvoicesIncremental = 'mobileFinanceInvoices',
  NativeFinanceExpenses = 'mobile.financeExpenses',
  NativeFinanceExpensesIncremental = 'mobileFinanceExpenses',
  NativeFinanceTaxDocument = 'mobile.financeTaxDocument',
  NativeFinanceTaxDocumentIncremental = 'mobileFinanceTaxDocument',
  NativeFinanceTransactions = 'mobile.financeTransactions',
  NativeFinanceTransactionsIncremental = 'mobileFinanceTransactions',
  NativeFinanceTransactionDetails = 'financeTransactionDetails',
  NativeFinanceTransactionForMobile = 'mobile.financeTransactionEnabledCustomizationForMobileWeb',
  //externalIcp
  externalIcpManagementEnabled = 'payrollConnect.externalIcpManagementEnabled',

  connectSelfActivationEnabled = 'payrollConnect.connectSelfActivationEnabled',
  // Health Insurance
  HealthInsuranceEnabled = 'extra.healthInsurance.enabled',
  // Multiple Signatures
  MultipleSignaturesForIC = 'multipleSignaturesForIC',
  MultipleSignaturesAmendmentsForIC = 'multipleSignaturesAmendmentsForIC',
  //Help
  HelpPageEnabled = 'askForHelpPage',
  HelpMenuItemEnabled = 'askForHelpMenuItem',
  helpCenterContactSupport = 'helpCenter.contactSupport',
  // Onboarding Call GSM
  eorOnboardingGSMCall = 'eorOnboardingGSMCall',
  eorOnboardingGSMCallBL = 'eorOnboardingGSMCallBL',
  // EOR Onboarding Asynchronous Automated Quote Enabled
  eorOnboardingAsynchronousAutomatedQuote = 'eorOnboarding.asynchronousAutomatedQuoteEnabled',
  // Compliance Hub
  complianceHubABTest = 'growth.complianceHubABTest',
  newCDDFlowEnabled = 'compliance.newCDDFlowEnabled',
  // GP
  guideMessageTerminationRequestInputtingPTO = 'guideMessageTerminationRequestInputtingPTO',
  gpSingleContractCreationFlowAutofillEmployeeNumberWithWorkerID = 'gpSingleContractCreationFlowAutofillEmployeeNumberWithWorkerID',
  // HRIS Core
  isChangeLogEnabledForDMs = 'isChangeLogEnabledForDMs',
  changelogModificationReasonFilterEnabled = 'changelogModificationReasonFilterEnabled',
  compensationSummaryForDeelers = 'compensationSummaryForDeelers',
  enableChangelogSubmissionDate = 'enableChangelogSubmissionDate',
  isWorkerDeletionEnabled = 'isWorkerDeletionEnabled',
  hrisGpAddressEdits = 'hrisGpAddressEdits',
  hrisCFContractPage = 'hrisCFContractPage',
  // Coverages
  selectingDeelCoverageInCreateContractorFlowExperimentId = 'deelCoverage.selectingDeelCoverageInCreateContractorFlowExperimentId',
  selectingDeelCoverageInCreateContractorFlowExperimentEnabled = 'selectingDeelCoverageInCreateContractorFlowExperimentEnabled',

  ActivationListEnabled = 'activationListEnabled',
  CompensationTypesForDE = 'compensationTypesForDE',
  // Contract Creation Approvals
  ContractCreationApprovalsForIC = 'contractCreationApprovalsForIC',
  // DHCM Compensation Managament
  CompensationSummary = 'compensationSummary',
  CompensationSummarySalaryInsights = 'compensationSummarySalaryInsights',
  EditCompensationFlow = 'editCompensationFlow',
  USPBenefits = 'USPBenefits',
  USPBenefitsAutoEnroll = 'USPBenefits.autoEnroll',
  // Background checks
  BackgroundCheckCheckrProvider = 'backgroundCheck.organizationsToStoreResponseFor',
  // Goldeen Deel AB Test
  isGoldeenDeelABTestEnabled = 'growth.isGoldeenDeelABTestEnabled',
  isEnableGoldenDeelOddOrgs = 'growth.isEnableGoldenDeelOddOrgs',
  // US Registration
  isUSRegistrationEnabled = 'isUSRegistrationEnabled',
  // ManagedHR
  enableMhrUpsellingCards = 'enableMhrUpsellingCards',
  // PayIns
  enabledAwaitingFundsTab = 'enabledAwaitingFundsTab',
  enabledPlaidTokenAPI = 'enabledPlaidTokenAPI',
  // New Employment agreement page
  isNewEmploymentAgreementPageEnabledGP = 'newEmploymentAgreementPageGP',
  // New Employment agreement EOR
  isNewEmploymentAgreementPageEOR = 'newEmploymentAgreementPageEOR',
  //New Employment agreement page for PEO
  isNewEmploymentAgreementPagePEO = 'newEmploymentAgreementPagePEO',
  IntegrationsBanner = 'AppStore.IntegrationsBanner',
  workersPlaidVerificationEnabled = 'workersPlaidVerificationEnabled',
  CompensationManagementJobArchitecture = 'compensationManagementJobArchitecture',
  chatAutomaticTranslation = 'chatAutomaticTranslation',
  chatEmailFlow = 'chat.emailFlow',
  setUserActiveInterval = 'setUserActiveInterval',
  // New GP Form validation
  isNewGPFormValidationEnabled = 'isNewGPFormValidationEnabled',
  isNewICFormValidationEnabled = 'isNewICFormValidationEnabled',
  isNewHRISFormValidationEnabled = 'isNewHRISFormValidationEnabled',
  isNewEORFormValidationEnabled = 'isNewEORFormValidationEnabled',
  isNewPEOFormValidationEnabled = 'isNewPEOFormValidationEnabled',
  minContractEndDateConfigByCountry = 'eor.minContractEndDateConfigByCountry',
  //New Employment agreement page for HRIS
  isNewEmploymentAgreementPageHRIS = 'newEmploymentAgreementPageHRIS',
  eorViewLatestG2NEnabled = 'eor.viewLatestG2NEnabled',
  AppStoreEnableExperimentId = 'appStoreEnableExperimentId', //
  ShowSnackbarErrorDetails = 'showErrorMessage',
  // Employee
  transitionToEmployeeABtest = 'growth.transitionToEmployeeABtest',
  // Compensation Management
  compensationManagementPermissions = 'compensationManagementPermissions',
  compensationManagementCycles = 'compensationManagementCycles',
  // Ask HRBP
  showAskHrbp = 'showAskHrbp',
  eorCountryDataMigrationExpansion = 'eor.countryDataMigration.expansion',
  // Shareable profiles
  isShareableProfilesEnabled = 'shareableProfiles.enabled',
  webAppNewVersionEnabled = 'webApp.newVersionEnabled',
  // Credit control and deposits
  clientAppRestrictionsWhitelistedRoutes = 'creditControl.clientAppRestrictions.whitelistedRoutes',
  // Engage Learning
  learningCertificatesEnabled = 'engage.learningCertificatesEnabled',
  PeoOnboardingRedesign = 'onboarding.peoOnboardingRedesign',
  ContractorTaxResidenceCountries = 'contractor.taxResidence.countries',
  benefitsAndExtrasExperimentABTest = 'growth.benefitsAndExtrasExperimentABTest',
  //Scope of work
  scopeOfWorkFlowRedesign = 'scopeOfWorkFlowRedesign',

  IsCalDaysDefaultCalcType = 'isCalDaysDefaultCalculationType',

  // adjustments
  adjustmentsPolicyFAQArticleId = 'adjustments.policyFAQArticleId',
}
