export enum PaymentMethodsEnum {
  ALVIERE_ACH = 'alviere_ach',
  ADYEN_CARD = 'adyen_card',
  STRIPE_ACH = 'stripe_ach',
  BANK_TRANSFER = 'bank_transfer',
  STRIPE_CARD = 'stripe_card',
  WISE = 'transferwise',
  MERCURY = 'mercury_wire',
  SEPA = 'stripe_sepa_debit',
  BACS = 'stripe_bacs_debit',
  PAD = 'go_cardless',
  BECS = 'go_cardless_becs',
  USDC = 'coinbase',
  BREX = 'brex',
  INTERNAL = 'internal',
  CLIENT_BALANCE = 'client_balance',
  FEE_CREDITS = 'fee_credits',
  EOR_FUNDING_BALANCE = 'eor_funding_balance',
  GENERIC_ACH = 'ach',
}
